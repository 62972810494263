<template>
    <header class="header" v-bind:class="{header__transparent: scrollPosition > 100}">
        <a href="#" class="header__logo">Portfolio <span>Geert de Leeuw</span></a>
        <input class="side-menu" type="checkbox" id="side-menu" v-model="menuChecked" ref="menuInput"/>
        <label class="hamburger" for="side-menu">
            <span class="hamburger__line"></span>
        </label>

        <nav class="navbar">
            <ul class="navbar__list">
                <li class="navbar__list-item" v-for="item in items" :key="item">
                    <a :href="`#` + item.id" class="navbar__link" @click="closeMenu">{{ item.title }}</a>
                </li>
            </ul>
        </nav>
    </header>
</template>

<script>
export default {
    data(){
        return {
            scrollPosition: null,
            menuChecked: false,
            items: [
                {
                    title: 'Wie ben ik',
                    id: 'wie'
                },
                {
                    title: 'Kennis en ervaring',
                    id: 'kennis'
                },
                {
                    title: 'Cases',
                    id: 'cases'
                },
            ],
        }
    },
    methods: {
        updateScroll() {
            this.scrollPosition = window.scrollY
        },
        closeMenu(){
            this.menuChecked = false;
        }
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    }
}
</script>
