<template>
    <div class="container-fluid container__large container__large--red" id="wie">
        <div class="row">
            <div class="col-12 col-lg-8 col-xl-6 offset-lg-2 offset-xl-3">
                <h2 class="h1 text-center">{{ title }}</h2>
                <p class="text-center">{{ text }}</p>
                <div class="btn__container">
                    <a href="#kennis" class="btn btn-secundary">{{ ctaTitle }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            title: 'Wie ben ik',
            text: 'Ik ben Geert de Leeuw, 37 jaar oud en ik woon samen met mijn vrouw en 2 kinderen in Dordrecht. In 2010 afgestudeerd aan de HBO opleiding Media Technologie en sindsdien veel werkervaring opgedaan (voornamelijk) als Front-end Developer.',
            ctaTitle: 'Waar ben ik goed in'
        }
    }

}
</script>
