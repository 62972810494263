<template>
    <div class="container-fluid container__large container__large--background-image" :style="backgroundImageInlineStyle">
        <div class="row">
            <div class="col-12 col-lg-8 col-xl-6 offset-lg-2 offset-xl-3 py-1 ">
                <h1 class="text-center">{{ title }}
                    <span class="small">{{ subTitle }}</span>
                </h1>
                <p class="text-center text-large">
                    {{ text }}
                </p>    
                <div class="btn__container">
                    <a href="#wie" class="btn btn-primary">{{ ctaTitle }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            title: 'Dit ben ik',
            subTitle: '(in het kort)',
            text: 'Ik ben een gedreven front-end developer met meer dan 7 jaar relevante werkervaring en altijd op zoek om mijn horizon te verbreden',
            ctaTitle: 'Meer over mij'
        }
    },
    computed: {
        backgroundImageInlineStyle () {
            return `background-image: url(${require('@/assets/images/header-min.jpg')})`;
        }
    },
}
</script>