<template>
  <nav-bar />
  <header-generic />
  <highlight />
  <usp-item />
  <case-item />
  <Footer-item />
</template>

<script>
import NavBar from './components/NavBar.vue'
import HeaderGeneric from './components/HeaderGeneric.vue'
import Highlight from './components/HighLight.vue'
import UspItem from './components/UspItem.vue'
import CaseItem from './components/CaseItem.vue'
import FooterItem from './components/FooterItem.vue'

export default {
  name: 'App',
  components: {
    HeaderGeneric,
    Highlight,
    NavBar,
    UspItem,
    CaseItem,
    FooterItem
  }
}
</script>
