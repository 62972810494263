<template>
    <div class="container container-fluid container__large case-item" id="cases">
        <div class="row">
            <div class="col-12 col-lg-8 offset-lg-2">
                <h2 class="h1 text-center">{{ title }}</h2>
                <p class="text-center">{{ text }}</p>
            </div>
            <div class="row">
                <div class="col-12" v-for="(caseItem, index) in cases" :key="index.key"  :class="{'case-item--last': index == cases.length - 1}">
                    <div class="row case-item__row" :class="{'case-item__row--even': index % 2 === 0}">
                        <div class="col-md-4">
                            <div class="case-item__sidebar">
                                <div class="case-item__image-container">
                                    <img :src="require(`@/assets/images/${caseItem.imageUrl}`)" :alt="caseItem.imageAlt" class="case-item__image"> 
                                </div>
                                <div class="case-item__content-container">
                                    <h3>Gebruikte technieken</h3>
                                    <ul class="technique-list">
                                        <li v-for="technique in caseItem.techniques" :key="technique.key">
                                            {{ technique }}
                                        </li>
                                    </ul>
                                    <h4>In opdracht van</h4>
                                    <p>{{ caseItem.bureau }}</p>
                                    <h4>Url</h4>
                                    <p><a :href="caseItem.link" class="case-item__link">{{ caseItem.linkText }}</a></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <h3 class="h2 case-item__title">
                                {{ caseItem.title }}
                            </h3>
                            <h4 class="h3">
                                {{ caseItem.subTitle }}
                            </h4>
                            <p v-html="caseItem.text"></p>    
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return {
            title: 'Cases',
            text: 'Een klein overzicht van het werk wat ik gemaakt heb. Dit is slechts een greep uit al de dingen die ik in de afgelopen periode gedaan heb',
            cases: [
                {
                    title: 'Verwonder om de Hoek',
                    subTitle: 'Website & Mijn-omgeving',
                    text: 'Voor Verwonderomdehoek heb ik de website onderhouden en verbeterd. En zijn we samen met de klant een traject gestart om de mijn-omgeving te bouwen. <br/><br/>Met de content vanuit de back-end heb ik de dashboard en verschillende weergaven toegoevoegd.<br/>Met behulp van Vue is het dashboard opgezet en samen met de klant is het dashboard en haar features getest. <br/>Dit project werd op basis van Scrum uitgevoegd in sprints van 2 weken.',
                    techniques: ['Scss', 'Html', 'js', 'Vue', 'Umbraco', 'Scrum', 'Jira'],
                    link: 'https://verwonderomdehoek.nl/',
                    linkText: 'verwonderomdehoek.nl',
                    imageUrl: "logo-vodh.svg",
                    imageAlt: 'Logo Verwonder om de Hoek',
                    bureau: 'W3S Digital'
                },
                {
                    title: 'Bonusan',
                    subTitle: 'Continuous development',
                    text: 'Bij Bonusan ben ik betrokken geweest bij het Continuous Development, als projectleider leidde ik de sprints in goede banen. <br/><br/>Met de data van de website en haar bezoeker zoeken we met de klant naar de beste oplossingen om de website en de conversie te verbeteren.<br/>Om hier een goed voorstel in te kunnen maken heb ik wireframes uitgewerkt voor de Front-end aanpassingen.<br/>Door deze aanpak was het mogelijk om efficient en snel de juiste aanpassingen te maken.<br/><br/>Binnen de website heb ik voornamelijk nieuwe onderdelen en verbeteringen op basis van Vue toegevoegd.',
                    techniques: ['Scss', 'Html', 'Figma', 'js', 'Vue', 'Umbraco', 'Scrum', 'Jira'],
                    link: 'https://bonusan.com/nl',
                    linkText: 'bonusan.com/nl',
                    imageUrl: "logo-bonusan.png",
                    imageAlt: 'Logo Bonusan',
                    bureau: 'W3S Digital'
                },
                {
                    title: 'Gemeente Utrecht',
                    subTitle: 'Intern als externe',
                    text: 'Bij de gemeente Utrecht ben ik actief geweest als externe (vanuit alterNET / Redkiwi) en werk ik op locatie aan het aanpassen en verbeteren van de website op basis van de feedback vanuit bezoekers en redacteuren. <br/><br/>Binnen het team Informatie Voorzieningen voer ik 1 dag in de week mijn werkzaamheden uit. Deze werkzaamheden bestaan voornamelijk uit het aanpassen en toevoegen van features aan de website. Naast alle front-end werkzaamheden werk ik aan de configuratie en optimalisatie van TYPO3 en bestaat het werk ook uit meedenken en adviseren van de klant.<br/><br/> De werkzaamheden zijn op basis van sprints en er word gebruik gemaakt van Scrum. Dat betekent onder andere dat de dag gestart word met een Daily standup en aan het einde van een sprint in een retro de afgelopen periode geanaliseerd word.',
                    techniques: ['Scss', 'Html', 'js', 'Git', 'Typo3', 'Scrum', 'Jira'],
                    link: 'https://utrecht.nl',
                    linkText: 'utrecht.nl',
                    imageUrl: "wapen-utrecht-rood.svg",
                    imageAlt: 'Logo Utrecht',
                    bureau: 'Alternet / Redkiwi'
                },
                {
                    title: 'Omgevingsdienst Zuid-Holland Zuid',
                    subTitle: 'Een nieuw design',
                    text: 'Voor de Omgevingsdienst moest een nieuwe website met een nieuw design gemaakt worden. Het oude design van de website was inmiddels gedateerd en aan vervanging toe. <br/><br/>Met de nieuwe website was het belangrijk dat deze goed te ondehouden was door de redacteuren.Het design heb ik Pixel-perfect omgezet, waarbij ik nauw contact hadden met de designer. Het was belangrijk om het ontwerp zorgvuldig om te zetten naar html en css om zo de uitstraling en huisstijl die hoort bij Ozhz te kunnen waarborgen.<br/><br/>Nadat de website ter test was opgeleverd bij de klant heb ik een aantal trainingen gegeven aan de redacteuren en daarin uitleg gegeven over de werking van het Cms en het toepassen van de verschillende elementen op de website.',
                    techniques: ['Scss', 'Html', 'js', 'Git', 'WordPress', 'Scrum', 'Jira'],
                    link: 'https://ozhz.nl',
                    linkText: 'ozhz.nl',
                    imageUrl: "logo-ozhz.png",
                    imageAlt: 'Logo Ozhz',
                    bureau: 'Alternet / Redkiwi'
                }
            ]
        }
    }

}
</script>
