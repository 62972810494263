<template>
    <div class="container container-fluid container__large footer-item">
        <div class="row">
            <div class="col-12 col-lg-8 offset-lg-2">
                <h2 class="h1 text-center">{{ title }}</h2>
                <p class="text-center">{{ text }}</p>
                <ul class="footer-item__list">
                    <li>
                        <a :href="`mailto:` + mail" class="footer-item__link-text">
                            <font-awesome-icon :icon="iconMail" class="icon-container__icon footer-item__icon"/>
                            <span>{{ mail }}</span>
                        </a>
                    </li>
                    <li>
                        <a :href="`tel:` + phoneLink" class="footer-item__link-text">
                            <font-awesome-icon :icon="iconPhone" class="icon-container__icon footer-item__icon"/>
                            <span>{{ phoneText }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>  
</template>

<script>
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

export default {
    data(){
        return {
            title: 'Meer weten',
            text: 'Interesse om een afspraak te maken en met mij in contact te komen of onder het genot van een cappuccino van gedachten te wisselen?',
            phoneText: '06 - 48 56 98 99',
            phoneLink: '+31648569899',
            mail: 'cv@geertdeleeuw.nl',
            iconPhone: faPhone,
            iconMail: faEnvelope
        }
    }
}
</script>
