<template>
    <div class="container container-fluid container__large" id="kennis">
        <div class="row">
            <div class="col-12 col-lg-8 offset-lg-2">
                <h2 class="h1 text-center">{{ title }}</h2>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-3" v-for="experience in experiences" :key="experience.key">
                    <div class="icon-container">
                        <h3 class="icon-container__title-container">
                            <span class="h2 icon-container__title">
                                {{ experience.title }}
                            </span>
                            <font-awesome-icon :icon="experience.icon.iconName" class="icon-container__icon"/>
                        </h3>
                    </div>
                    <p class="text-center padding-small">{{ experience.text }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { faClipboardList, faHighlighter, faDumbbell, faPeopleGroup } from '@fortawesome/free-solid-svg-icons'

export default {
    data(){
        return {
            title: 'Kennis en ervaring',
            ctaTitle: 'Waar ben ik goed in',
            experiences: [
                {
                    title: 'Compleet',
                    text: 'Goed met HTML5, Scss, Javascript, Vue, Jira, Git en Scrum.',
                    icon: faClipboardList
                },
                {
                    title: 'Oog voor detail',
                    text: 'Niet alleen codekloppen, maar ook designen en meedenken over UX, UI en toegankelijkheid.',
                    icon: faHighlighter
                },
                {
                    title: 'Teamspeler',
                    text: 'Sociaal, inspirerend en een voorliefde om samen met een team doelen te verwezenlijken.',
                    icon: faPeopleGroup
                },
                {
                    title: 'Sportief',
                    text: 'Naast het werk ook tijd voor ontspanning in de sportschool of op de fiets.',
                    icon: faDumbbell
                },
            ]
        }
    }

}
</script>
