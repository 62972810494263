import { createApp } from 'vue'
import App from './App.vue'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { faHighlighter } from "@fortawesome/free-solid-svg-icons";
import { faDumbbell } from "@fortawesome/free-solid-svg-icons";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faClipboardList);
library.add(faHighlighter);
library.add(faDumbbell);
library.add(faPeopleGroup);
library.add(faPhone);
library.add(faEnvelope);

import './scss/main.scss'

createApp(App)
.component("font-awesome-icon", FontAwesomeIcon)
.mount('#app')
